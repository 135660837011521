<template>
    <v-autocomplete v-model="selectedStakeholders" :items="displayStakeholders" :loading="isLoading" :disabled="disabled" item-value="id" clear-icon="far fa-times-circle" :search-input.sync="search" @focus="$emit('focus')" :menu-props="{ bottom: true, offsetY: true }" @change="$emit('input', selectedStakeholders)" :multiple="multiple" :label="label" :placeholder="placeholder" :error-messages="errors" :hide-details="!failed" no-data-text="Commencez à taper pour lancer une recherche" :class="{ 'flex-grow-0': flexGrow0 }" color="app-blue" background-color="white" no-filter outlined clearable dense>
        <template v-slot:selection="data">
            <v-chip v-if="data.index === 0" small label>
                <v-icon color="blue darken-2" left> fas fa-user </v-icon>
                {{ data.item.fullname }}
            </v-chip>

            <span v-if="data.index === 1" class="grey--text text-caption">
                (+{{ selectedStakeholders.length - 1 }} autres)
            </span>
        </template>

        <template v-slot:prepend>
            <slot name="prepend" />
        </template>

        <template v-slot:item="{ item }">
            <v-list-item-avatar class="blue white--text text-uppercase headline">
                {{ item.fullname.slice(0, 1) }}
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title>
                    {{ item.fullname }}
                </v-list-item-title>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    name: 'AppointmentStakeholderAutocomplete',

    props: {
        value: { required: true },
        label: { type: String },
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        flexGrow0: { type: Boolean, default: false },
        noDynamicSearch: { type: Boolean, default: false },
        errors: { type: Array },
        failed: { type: Boolean, default: false },
        initStakeholders: { type: Array }
    },

    data: () => ({
        selectedStakeholders: null,
        stakeholders: [],
        displayStakeholders: [],
        isLoading: false,
        search: null,
        searchTimeout: null
    }),

    watch: {
        value() {
            this.selectedStakeholders = this.value;
            // console.log('ok');
            // if (!this.value && this.multiple) {
            //     console.log('reset');
            //     this.selectedStakeholders = [];
            // }
        },

        search(search) {
            if (this.noDynamicSearch) {
                if (typeof search !== 'string' || search.length.length < 3) {
                    this.displayStakeholders = this.stakeholders;
                } else {
                    this.displayStakeholders = this.stakeholders.filter((p) => {
                        return p.name.toLowerCase().includes(search.toLocaleLowerCase());
                    });
                }
                return;
            }

            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                if (typeof search !== 'string' || search.length.length < 3) {
                    return;
                }
                if (this.isLoading) {
                    return;
                }

                this.isLoading = true;
                const query = {
                    limit: 5,
                    quickSearch: search
                };
                this.fetchStakeholders(query);
            }, 500);
        },

        initStakeholders() {
            this.displayStakeholders = this.initStakeholders;
        }
    },

    computed: {
        noDataText() {
            if (this.noDynamicSearch) {
                return 'Aucun programme disponible';
            } else {
                return 'Commencez à taper pour lancer une recherche';
            }
        }
    },

    methods: {
        async fetchStakeholders(query = {}) {
            try {
                query.orderBy = 'fullname:asc';

                this.isLoading = true;
                const { stakeholders } = await this.repos.appointments.getStakeholders(query);
                this.stakeholders = stakeholders;
                this.displayStakeholders = stakeholders;
            } catch (err) {
                console.error(err);
            } finally {
                this.isLoading = false;
            }
        },

        reset() {
            this.selectedStakeholders = [];
        }
    },

    created() {
        this.selectedStakeholders = this.value;
        if ((Array.isArray(this.selectedStakeholders) && this.selectedStakeholders.length > 0) || typeof this.selectedStakeholders === 'number') {
            const stakeholders = Array.isArray(this.selectedStakeholders) ? this.selectedStakeholders : [this.selectedStakeholders];
            this.fetchStakeholders({ stakeholders });
        }
        if (this.initStakeholders) {
            this.displayStakeholders = this.initStakeholders;
        }
    }
};
</script>